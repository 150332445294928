import {Component, OnInit} from '@angular/core';
import {ModalController} from '@ionic/angular';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import {getSlots, sleep, venueAcceptsOrders} from 'src/smoothr-web-app-core/utils/utils';

@Component({
	selector: 'app-select-order-type-modal',
	templateUrl: './select-order-type-modal.component.html',
	styleUrls: ['select-order-type-modal.component.scss']
})
export class SelectOrderTypeModalComponent implements OnInit {
	preorderTypesArr = [PreorderType.TAKE_AWAY, PreorderType.DELIVERY];
	selectedVenue: Venue;
	acceptsDelivery = false;
	acceptsTakeaway = false;
	constructor(private modalCtrl: ModalController) {}

	static async show(modalCtrl: ModalController, venue: Venue): Promise<PreorderType> {
		const modal = await modalCtrl.create({
			component: SelectOrderTypeModalComponent,
			cssClass: 'delivery-modal',
			componentProps: {
				selectedVenue: venue
			}
		});

		await modal.present();

		const result = await modal.onDidDismiss();
		await sleep(100);
		return result.data;
	}
	ngOnInit(): void {
		this.checkOrderTypeIsActive();
	}
	dismiss() {
		this.modalCtrl.dismiss();
	}
	selectedPreorderType(type: PreorderType) {
		if (this.accepts(type)) {
			this.modalCtrl.dismiss(type);
		}
	}
	checkOrderTypeIsActive() {
		getSlots(this.selectedVenue, OrderType.PREORDER, PreorderType.TAKE_AWAY, null, 0, false, 1).then(slots => {
			this.acceptsTakeaway = slots.length > 0 && venueAcceptsOrders(this.selectedVenue, PreorderType.TAKE_AWAY);
		});
		getSlots(this.selectedVenue, OrderType.PREORDER, PreorderType.DELIVERY, null, 0, false, 1).then(slots => {
			this.acceptsDelivery = slots.length > 0 && venueAcceptsOrders(this.selectedVenue, PreorderType.DELIVERY, false);
		});
	}
	accepts(type: PreorderType): boolean {
		switch (type) {
			case PreorderType.DELIVERY:
				return this.acceptsDelivery;
			case PreorderType.TAKE_AWAY:
				return this.acceptsTakeaway;
			default:
				return false;
		}
	}
}
