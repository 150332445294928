import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AlertController, ModalController} from '@ionic/angular';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MenuPage} from '../menu/menu.page';
import {AppComponent} from '../../app.component';
import {TranslateService} from '@ngx-translate/core';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {SelectOrderTypeModalComponent} from 'src/app/components/select-order-type-modal/select-order-type-modal.component';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import {sleep} from 'src/smoothr-web-app-core/utils/utils';
import Venue from 'src/smoothr-web-app-core/models/Venue';
import Address from 'src/smoothr-web-app-core/models/Address';
import {CheckDeliveryRadiusModalComponent} from 'src/app/components/check-delivery-radius-modal/check-delivery-radius-modal.component';

@Component({
	selector: 'app-order',
	templateUrl: './order.page.html',
	styleUrls: ['order.page.scss']
})
export class OrderPage extends RepositoryDirective implements OnInit {
	static url = 'order';
	static failUrlWithPaymentParam = 'payment/fail/:payment';
	static cancelUrlWithPaymentParam = 'payment/cancel/:payment';
	static paymentFailUrl = 'payment/fail';
	static paymentCancelUrl = 'payment/cancel';
	loading = false;

	constructor(
		protected repository: RepositoryService,
		private translate: TranslateService,
		private snackbarCtrl: MatSnackBar,
		private router: Router,
		private modalCtrl: ModalController
	) {
		super(repository);
	}

	get title(): string {
		if (!OrderUtils.isPreorder(this.order)) {
			return '';
		}
		return this.translate.instant('order_page.title');
	}

	static navigate(router: Router) {
		return router.navigateByUrl(AppComponent.largeScreen ? MenuPage.url : OrderPage.url);
	}

	ngOnInit(): void {
		super.ngOnInit();
		console.log('ngOnInit');
		switch (window.location.pathname) {
			case '/' + OrderPage.url:
				break;
			case '/' + OrderPage.paymentCancelUrl:
				console.log(this.translate.instant('order.payment_cancel'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_cancel'), null, {
					duration: 2000
				});
				break;
			case '/' + OrderPage.paymentFailUrl:
				console.log(this.translate.instant('order.payment_fail'));
				this.snackbarCtrl.open(this.translate.instant('order.payment_fail'), null, {
					duration: 5000
				});
				break;
		}
	}

	backClick() {
		MenuPage.navigate(this.router);
	}
	async changeType() {
		const selectOrderTypeResult = await SelectOrderTypeModalComponent.show(this.modalCtrl, this.venue);
		if (selectOrderTypeResult) {
			if (selectOrderTypeResult && selectOrderTypeResult == PreorderType.TAKE_AWAY) {
				this.loading = true;
				// this.repository.createOrder(this.venue, null, OrderType.PREORDER, selectOrderTypeResult);
				this.order.preorder.type = PreorderType.TAKE_AWAY;

				this.repository.order.emit(this.order);
				await sleep(300);
				window.location.reload();
				this.loading = false;
				return;
			}
			if (selectOrderTypeResult && selectOrderTypeResult == PreorderType.DELIVERY) {
				console.log('WE ARE YOunG');
				const result: {venue: Venue; address: Address} = await CheckDeliveryRadiusModalComponent.show(
					this.modalCtrl,
					this.venue
				);
				if (result) {
					this.loading = true;
					// this.repository.venue.emit(await this.repository.getVenue(result.venue._id));
					this.repository.address.emit(result.address);
					this.order.preorder.type = PreorderType.DELIVERY;
					console.log(this.order);
					this.repository.order.emit(this.order);
					await sleep(300);
					window.location.reload();

					this.loading = false;
				}
			}
		}
	}
}
