import {Component, OnInit} from '@angular/core';
import * as moment from 'moment';
import {AlertController} from '@ionic/angular';
import {ActivatedRoute, Router} from '@angular/router';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import {HomePage} from '../home/home.page';
import {Api} from '../../../smoothr-web-app-core/api/api';
import Venue from '../../../smoothr-web-app-core/models/Venue';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {environment} from '../../../environments/environment';
import {AnalyticsService} from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import {numberToCurrency, sleep, getPrice, numberD, firstOfObservable} from '../../../smoothr-web-app-core/utils/utils';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import {OrderType} from 'src/smoothr-web-app-core/enums/OrderType';
import {interval} from 'rxjs';
import {catchError, map, startWith, switchMap} from 'rxjs/operators';
import Order from 'src/smoothr-web-app-core/models/Order';

@Component({
	selector: 'app-payment-success',
	templateUrl: './payment-success.page.html',
	styleUrls: ['payment-success.page.scss']
})
export class PaymentSuccessPage extends RepositoryDirective implements OnInit {
	static url = 'payment/success';
	static urlWithPaymentParam = 'payment/success/:payment';
	static ORDER_ID_PARAM = 'order';
	moment = moment;
	OrderUtils = OrderUtils;
	pt = PreorderType;
	orderVenue: Venue;
	error: any;
	orderId: string;
	environment = environment;
	numberToCurrency = numberToCurrency;
	loading = true;
	numberD = numberD;
	display = null;
	tip = 0;
	interval$ = null;
	isOrderIsConfirmed = false;
	constructor(
		private translate: TranslateService,
		protected repository: RepositoryService,
		private alertCtrl: AlertController,
		private router: Router,
		private snackbarCtrl: MatSnackBar,
		private route: ActivatedRoute,
		private analytics: AnalyticsService
	) {
		super(repository);
	}

	static async navigate(router: Router, order: string, payment: string) {
		await router.navigate([this.url + '/' + payment], {
			queryParams: {
				order
			}
		});
	}

	ionViewDidEnter() {
		console.log(this.verifiedOrder);
		this.repository.order.emit(null);
	}

	async onInitFinish() {
		super.onInitFinish();
		this.route.queryParams.subscribe(params => {
			console.log({
				params
			});
			if (this.verifiedOrder && this.verifiedOrder._id && !params.order) {
				this.orderId = this.verifiedOrder._id;
			} else {
				this.orderId = params.order;
			}
			this.reloadOrder();
		});
	}

	async reloadOrder(attempt: number = 0, error = null) {
		this.error = null;
		this.loading = true;
		if (attempt > 2) {
			this.error = error ? error : this.translate.instant('payment_success_page.payment_error');
			this.snackbarCtrl.open(error, null, {
				duration: 2000
			});
			this.loading = false;
			return;
		}
		if (attempt > 0) {
			await sleep(200);
		}
		try {
			this.getDeliveryTime(this.orderId);
			const orderResponse = await Api.getOrder(this.orderId);
			this.repository.verifiedOrder.emit(orderResponse.data);
			if (!this.verifiedOrder.isPayed) {
				await this.reloadOrder(attempt + 1, this.translate.instant('payment_success_page.please_reload'));
				return;
			}
			const params = await firstOfObservable(this.route.params);
			if (params.payment) {
				const payment = (await Api.getPayment(params.payment)).data;
				this.tip = numberD(payment.tip);
			}
			this.analytics.paymentSuccess();

			// const deliveryTime = await Api.getDeliveryTime(orderResponse.data._id);
			// console.log('DELIVERY TIME', deliveryTime);
			try {
				if (this.venue && this.venue._id === this.verifiedOrder.venue) {
					this.orderVenue = this.venue;
				} else {
					this.orderVenue = (await Api.getLazyVenue(this.verifiedOrder.venue)).data;
				}
				this.error = false;
				this.loading = false;
			} catch (e) {
				console.error(e);
				await this.reloadOrder(attempt + 1, error ? error : e);
				return;
			}
		} catch (e) {
			console.error(e);
			await this.reloadOrder(attempt + 1, e);
			return;
		}
	}

	home() {
		if (this.loading) {
			return;
		}
		if (this.error) {
			this.reloadOrder();
			return;
		}
		this.loading = true;
		HomePage.navigate(this.router, this.repository);
	}

	priceOfOption(articleGroup: ArticleGroup, articleOption: ArticleOption): number {
		const price = getPrice(articleOption.article, OrderType.PREORDER, this.verifiedOrder?.preorder?.type);
		if (OrderUtils.isBogoOrFreeArticlePromo(this.verifiedOrder) && articleGroup.isPromo) {
			return 0;
		}
		return price;
	}
	async getDeliveryTime(orderId: string) {
		this.interval$ = interval(30000)
			.pipe(
				startWith(0),
				switchMap(() => Api.getOrder(orderId)),
				map(order => {
					if (order.data.estimatedTime && order.data.preorder.type === PreorderType.DELIVERY) {
						this.isOrderIsConfirmed = true;
						this.setupTimer(order.data.estimatedTime);
						this.stopTimer();
					}
					return order;
				}),
				catchError(e => {
					throw e;
				})
			)
			.subscribe(order => {
				this.repository.verifiedOrder.emit(order.data);
			});
	}
	stopTimer() {
		this.interval$.unsubscribe();
	}
	private setupTimer(date: string) {
		console.log(date);
		const myTime = moment();
		const finishedTime = moment(date);
		const diffTime = finishedTime.diff(myTime, 'm');
		if (diffTime > 0) {
			let seconds = diffTime * 60;
			let textSec: any = '0';
			let statSec = 60;

			const prefix = diffTime < 10 ? '0' : '';

			const timer = setInterval(() => {
				seconds--;
				if (statSec != 0) statSec--;
				else statSec = 59;

				if (statSec < 10) {
					textSec = '0' + statSec;
				} else textSec = statSec;

				this.display = `${prefix}${Math.floor(seconds / 60)}` + ' minutes ' + `${textSec}` + ' seconds';

				if (seconds == 0) {
					this.display = '-';
					console.log('finished');
					clearInterval(timer);
				}
			}, 1000);
		} else {
			this.display = this.translate.instant('payment_success_page.delivery_time_over');
		}
	}
	calculateTime(order: Order) {
		const timeDiff = moment(order?.orderAt).diff(moment(), 'minutes');
		return timeDiff > 0 ? timeDiff : 0;
	}
	deliveryTime(order: Order) {
		const timeDiff = moment(order?.orderAt).diff(moment(), 'minutes');
		if (timeDiff > 60) {
			return (
				this.translate.instant('payment_success_page.delivery_text') +
				moment(order.orderAt).format('DD.MM.yyyy') +
				' um ca.' +
				moment(order.orderAt).format('HH:mm') +
				' ' +
				this.translate.instant('payment_success_page.delivery_text_next')
			);
		} else {
			return (
				this.translate.instant('payment_success_page.delivery_text_asap') +
				timeDiff +
				this.translate.instant('payment_success_page.delivery_text_next_asap')
			);
		}
	}
	abholungTime(order: Order) {
		const timeDiff = moment(order?.orderAt).diff(moment(), 'minutes');
		if (timeDiff > 60) {
			return (
				this.translate.instant('payment_success_page.text_info') +
				moment(order.orderAt).format('DD.MM.yyyy') +
				' um ' +
				moment(order.orderAt).format('HH:mm') +
				' ' +
				this.translate.instant('payment_success_page.text_info_next')
			);
		} else {
			return (
				this.translate.instant('payment_success_page.text_info_asap') +
				timeDiff +
				this.translate.instant('payment_success_page.text_info_next_asap')
			);
		}
	}
}
