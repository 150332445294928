import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Router} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {HomePage} from 'src/app/pages/home/home.page';
import {MenuPage} from 'src/app/pages/menu/menu.page';
import {PreorderType} from 'src/smoothr-web-app-core/enums/PreorderType';
import {RepositoryService} from 'src/smoothr-web-app-core/services/repository/repository.service';

enum Language {
	GreatBritain = 'en',
	German = 'de'
}

@Component({
	selector: 'app-toolbar-burger-menu',
	templateUrl: './toolbar-burger-menu.component.html',
	styleUrls: ['./toolbar-burger-menu.component.scss']
})
export class ToolbarBurgerMenuComponent implements OnInit {
	languageEnum = Language;

	@Output() backClick = new EventEmitter<void>();
	@Input() preorderType: PreorderType;
	@Input() showChangeType = false;
	@Output() changeType = new EventEmitter<void>();
	constructor(private router: Router, public repository: RepositoryService, public translate: TranslateService) {}
	ngOnInit(): void {}

	goToHome() {
		this.backClick.emit();
	}

	changeLang(language: string) {
		this.translate.setDefaultLang(language);
		this.translate.use(language);
	}
	changeOrderType() {
		this.changeType.emit();
	}
}
