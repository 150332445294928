import {apiEnvironment} from '../smoothr-web-app-core/environments/apiEnvironment';

export const naupakaCustomerGroup = 'naupaka';
export const customerGroup = naupakaCustomerGroup;

const supportEmail = {
	naupaka: 'naupaka@smoothr.de'
};
const firebaseConfig = {
	naupaka: {
		apiKey: 'AIzaSyAL0ni5N76N1k9nFrZB2A0_Br3lOgr-f5s',
		authDomain: 'naupaka-web-app.firebaseapp.com',
		projectId: 'naupaka-web-app',
		storageBucket: 'naupaka-web-app.appspot.com',
		messagingSenderId: '617590905663',
		appId: '1:617590905663:web:ae6c3548283537648a118a',
		measurementId: 'G-HFVXXZMPTM'
	}
};
export const environment = {
	customerGroup,
	webAppUrl: window.location.protocol + '//' + window.location.host,
	placesApiKey: 'AIzaSyAwmO4lTWVDQuNavc4mFERD0oPR4hBBWYY',
	SUPPORT_EMAIL: supportEmail[customerGroup],
	firebaseConfig: firebaseConfig[customerGroup],
	APAY_MERCHANT_ID: 'merchant.smoothr.' + customerGroup + '-dev',
	...apiEnvironment,
	gaId: '',
	countryList: ['de']
};

import 'zone.js/dist/zone-error';
