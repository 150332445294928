import {Component, Input} from '@angular/core';
import Article from '../../../smoothr-web-app-core/models/Article';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import ArticleGroup from '../../../smoothr-web-app-core/models/ArticleGroup';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {environment} from 'src/environments/environment';
import {defaultsToArticleOption, getPrice, numberD, numberToCurrency} from '../../../smoothr-web-app-core/utils/utils';
import {TranslateService} from '@ngx-translate/core';

@Component({
	selector: 'app-item-view',
	templateUrl: './item-view.component.html',
	styleUrls: ['item-view.component.scss']
})
export class ItemViewComponent {
	@Input() currency: string;
	price: number;
	ot = OrderType;
	environment = environment;
	@Input() hasOrder = false;
	priceText = '';
	numberToCurrency = numberToCurrency;

	constructor(private translate: TranslateService) {}

	private _article: Article;

	get article(): Article {
		return this._article;
	}

	@Input()
	set article(value: Article) {
		this._article = value;
		this.reloadPrice();
	}

	private _preorderType: PreorderType = null;

	get preorderType(): PreorderType {
		return this._preorderType;
	}

	@Input()
	set preorderType(value: PreorderType) {
		this._preorderType = value;
		this.reloadPrice();
	}

	private reloadPrice() {
		if (this.article && this.preorderType) {
			const articleGroup = new ArticleGroup();
			articleGroup.article = this.article;
			articleGroup.groups = defaultsToArticleOption(
				this.article,
				[],
				this.article.defaults,
				OrderType.PREORDER,
				this.preorderType
			);
			articleGroup.quantity = 1;
			const groups = this.test(articleGroup);
			this.price = OrderUtils.articleGroupsTotalPrice([articleGroup], OrderType.PREORDER, this.preorderType);

			if (
				groups.length > 0 &&
				this.article?.tags?.length > 0 &&
				this.article.tags?.filter(it => it.identifier === 'fromPrice').length > 0
			) {
				if (groups.length > 0) {
					const groupPrice = groups
						.filter(it => it.requiredAmount > 0)
						.map(it => {
							return Math.min(
								...it.articles.map(
									article => getPrice(article, OrderType.PREORDER, this.preorderType) * it.requiredAmount
								)
							);
						});
					this.price = groupPrice.reduce((a, b) => a + b, 0);
					return;
				} else {
					this.price = OrderUtils.articleGroupsTotalPrice([articleGroup], OrderType.PREORDER, this.preorderType);
				}
			} else {
				this.price = OrderUtils.articleGroupsTotalPrice([articleGroup], OrderType.PREORDER, this.preorderType);
			}
		} else {
			this.price = 0;
		}
		return this.price;
		// this.priceText = numberToCurrency(this.price, this.currency);
	}
	test(articleGroup: ArticleGroup) {
		let pages = [];
		for (const group of articleGroup.article.groups) {
			pages.push(group);
		}
		if (pages.length === 0) {
			pages.push([]);
		}
		if (pages.length > 1) {
			pages = pages.filter(page => page !== null);
		}
		let indicesOfRecommendations = [];
		pages.forEach((page, pageIndex) => {
			if (
				page.length > 0 &&
				articleGroup.article.recommendations.find(recommendation => recommendation.group === page[0]._id)
			) {
				indicesOfRecommendations.push(pageIndex);
			}
		});
		return pages;
	}
	isTagPriceFrom() {
		return this.article &&
			this.article.tags.length > 0 &&
			this.article?.tags?.filter(tag => tag.identifier === 'fromPrice')
			? this.translate.instant('item_view.price_from')
			: '';
	}
}
