import { HomePage } from 'src/app/pages/home/home.page';
import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'app-map',
	templateUrl: './map.page.html',
	styleUrls: ['map.page.scss'],
})
export class MapPage {
	static url = 'map';
	constructor(private router: Router) {}
	static navigate(router: Router) {
		return router.navigateByUrl(MapPage.url);
	}
	goToHome() {
		HomePage.navigate(this.router)
	}
}
