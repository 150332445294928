import {Injectable} from '@angular/core';
import {Params, Router} from '@angular/router';

export const NAVIGATIONURLS = {
	tos: () => 'tos',
	home: () => 'home',
	menu: () => 'menu',
	order: () => 'order',
	signIn: () => 'sign-in',
	signUp: () => 'sign-up',
	privacy: () => 'privacy',
	account: () => 'account',
	myOrders: () => 'my-orders',
	impressum: () => 'impressum',
	maintenance: () => 'maintenance',
	emailAction: () => 'email-action',
	paymentFail: () => 'payment/fail',
	selectVenue: () => 'select-venue',
	confirmEmail: () => 'confirm-email',
	paymentCancel: () => 'payment/cancel',
	paymentSuccess: () => 'payment/success',
	paymentFailPaymentId: (paymentId?: string) => `payment/fail/${paymentId}`,
	paymentCancelPaymentId: (paymentId?: string) => `payment/cancel/${paymentId}`,
	paymentSuccessPaymentId: (paymentId?: string) => `payment/success/${paymentId}`
};
export const navPaymentSuccess = async (router: Router, order: string, payment: string) => {
	await router.navigate([NAVIGATIONURLS.paymentSuccessPaymentId(payment)], {
		queryParams: {
			order
		}
	});
};
@Injectable({
	providedIn: 'root'
})
export class NavigationService {
	constructor(private router: Router) {}
	async menu() {
		await this.router.navigateByUrl(NAVIGATIONURLS.menu());
	}

	async home() {
		await this.router.navigateByUrl(NAVIGATIONURLS.home());
	}

	async order(largeScreen = false) {
		return this.router.navigateByUrl(largeScreen ? NAVIGATIONURLS.menu() : NAVIGATIONURLS.order());
	}

	async imprint() {
		await this.router.navigateByUrl(NAVIGATIONURLS.impressum());
	}

	async paymentSuccess(order: string, payment: string) {
		await this.router.navigate([NAVIGATIONURLS.paymentSuccessPaymentId(payment)], {
			queryParams: {
				order
			}
		});
	}
	async maintenance() {
		await this.router.navigateByUrl(NAVIGATIONURLS.maintenance(), {
			skipLocationChange: true,
			replaceUrl: false
		});
	}
	async tos() {
		await this.router.navigateByUrl(NAVIGATIONURLS.tos());
	}
	async signUp() {
		await this.router.navigateByUrl(NAVIGATIONURLS.signUp());
	}
	async signIn() {
		await this.router.navigateByUrl(NAVIGATIONURLS.signIn());
	}
	async myOrders() {
		await this.router.navigateByUrl(NAVIGATIONURLS.myOrders());
	}
	async account() {
		await this.router.navigateByUrl(NAVIGATIONURLS.account());
	}
	async privacy() {
		await this.router.navigateByUrl(NAVIGATIONURLS.privacy());
	}
	async emailConfirmation() {
		await this.router.navigateByUrl(NAVIGATIONURLS.confirmEmail());
	}
	async navigateToUrl(url: string) {
		await this.router.navigateByUrl(url);
	}
	async selectVenue() {
		await this.router.navigateByUrl(NAVIGATIONURLS.selectVenue());
	}
	async navigateToUrlWithParams(url: string, queryParams: Params) {
		await this.router.navigate([url], {
			queryParams,
			replaceUrl: true
		});
	}
}
