import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import {getPrice, getSlotsByOrder} from '../../../smoothr-web-app-core/utils/utils';
import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {IonContent, IonSlides, ModalController, PickerController} from '@ionic/angular';
import {RepositoryService} from '../../../smoothr-web-app-core/services/repository/repository.service';
import * as moment from 'moment';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import validator from 'validator';
import {TosPage} from '../../pages/tos/tos.page';
import {PrivacyPage} from '../../pages/privacy/privacy.page';
import RepositoryDirective from '../../../smoothr-web-app-core/directives/repository-directive';
import {MatSnackBar} from '@angular/material/snack-bar';
import {TranslateService} from '@ngx-translate/core';
import {Moment} from 'moment';
import {ValidationUtils} from '../../../smoothr-web-app-core/utils/validation-utils';
import {OrderUtils} from '../../../smoothr-web-app-core/utils/order-utils';
import {Router} from '@angular/router';
import {calculateGeoDistance, MapsUtils} from '../../../smoothr-web-app-core/utils/maps-utils';
import {sleep, isVenueOpen, numberToCurrency} from '../../../smoothr-web-app-core/utils/utils';
import Order from '../../../smoothr-web-app-core/models/Order';
import {AnalyticsService} from '../../../smoothr-web-app-core/services/analytics/analytics.service';
import {TimeUtils} from '../../../smoothr-web-app-core/utils/time-utils';
import Article from '../../../smoothr-web-app-core/models/Article';
import ArticleOption from 'src/smoothr-web-app-core/models/ArticleOption';
import ArticleGroup from 'src/smoothr-web-app-core/models/ArticleGroup';
import {environment} from 'src/environments/environment';
import {PhoneNumber, PhoneNumberUtil} from 'google-libphonenumber';
import {AbstractControl, FormBuilder, FormGroup, Validators} from '@angular/forms';
@Component({
	selector: 'app-checkout-modal',
	templateUrl: './checkout-modal.component.html',
	styleUrls: ['checkout-modal.component.scss']
})
export class CheckoutModalComponent extends RepositoryDirective implements OnInit {
	orderUtils = OrderUtils;
	loading = false;
	now: moment.Moment;
	pt = PreorderType;
	nameError: string;
	emailError: string;
	phoneError: string;
	streetNumberError: string;
	orderAtError: string;
	streetError: string;
	postalCodeError: string;
	hadPostalCode = false;
	telCountryOptions: any = {
		cssClass: 'tel-country-popover'
	};
	isVenueOpen = false;
	tip: number = 0;
	numberToCurrency = numberToCurrency;
	slots: Moment[] = [];
	firstSlotDelivery = null;
	moment = moment;

	environment = environment;

	orderForm: FormGroup;
	fullName = 'fullName';
	email = 'email';
	phone = 'phone';
	note = 'note';
	street = 'street';
	houseNumber = 'houseNumber';
	postcode = 'postcode';
	city = 'city';
	orderAt = 'orderAt';

	@ViewChild(IonContent, {static: false}) content;
	@ViewChild(IonSlides, {static: false}) slides: IonSlides;
	slideOptions = {
		spaceBetween: 0,
		initialSlide: 0,
		speed: 400,
		allowTouchMove: false
	};
	isOverviewPage = false;
	constructor(
		private modalCtrl: ModalController,
		protected repository: RepositoryService,
		private snackbarCtrl: MatSnackBar,
		private pickerCtrl: PickerController,
		private translate: TranslateService,
		private router: Router,
		private analytics: AnalyticsService,
		private cdr: ChangeDetectorRef,
		private fb: FormBuilder
	) {
		super(repository);
		if (this.address && this.address.postalCode && this.address.postalCode.length > 0) {
			this.hadPostalCode = true;
		}
		if (this.order && this.order.preorder && this.order.preorder.postalCode && this.address.postalCode.length > 0) {
			this.hadPostalCode = true;
		}
	}

	static async show(modalCtrl: ModalController, analytics: AnalyticsService, order: Order, tip: number) {
		const modal = await modalCtrl.create({
			component: CheckoutModalComponent,
			componentProps: {
				tip
			},
			showBackdrop: true,
			backdropDismiss: true
		});
		await modal.present();
		analytics.beginCheckout(order);
		return await modal.onDidDismiss();
	}

	ngOnInit() {
		super.ngOnInit();
		this.analytics.checkoutProgress(this.order, null, 1);
		this.analytics.customerBeginsCheckout();
		this.loadSlots();
		this.createOrderForm();
		this.orderForm.valueChanges.subscribe(v => console.log(v));
	}

	createOrderForm() {
		const formControlNames: any = {};
		formControlNames[this.phone] = ['', [Validators.required, this._customPhoneValidator]];

		formControlNames[this.fullName] = ['', [Validators.required, Validators.pattern(/\w+\s\w+/)]];
		formControlNames[this.email] = [
			'',
			[
				Validators.required,
				Validators.pattern(
					"^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"
				)
			]
		];
		formControlNames[this.phone] = ['', [Validators.required]];
		formControlNames[this.note] = [''];
		console.log(
			!TimeUtils.doesHoursMatchNow(this.venue.openingHours) && this.order?.preorder?.type === PreorderType.TAKE_AWAY
		);
		if (
			!TimeUtils.doesHoursMatchNow(this.venue.openingHours) &&
			this.order?.preorder?.type === PreorderType.TAKE_AWAY
		) {
			console.log('1');
			formControlNames[this.orderAt] = ['', [Validators.required]];
		} else if (
			!TimeUtils.doesHoursMatchNow(this.venue.deliveryHours) &&
			this.order?.preorder?.type === PreorderType.DELIVERY
		) {
			console.log('2');

			formControlNames[this.orderAt] = ['', [Validators.required]];
		} else {
			formControlNames[this.orderAt] = [''];
		}
		if (this.order?.preorder?.type === PreorderType.DELIVERY) {
			formControlNames[this.street] = ['', [Validators.required]];
			formControlNames[this.houseNumber] = ['', [Validators.required]];
			formControlNames[this.postcode] = ['', [Validators.required]];
			formControlNames[this.city] = ['', [Validators.required]];
		}

		this.orderForm = this.fb.group(formControlNames);
		this.reloadAddress();
		this.checkPrevOrder();
	}

	getFormControlValue(controlName?: string) {
		if (controlName) {
			return this.orderForm.get(controlName)?.value;
		}
		return '';
	}
	isValid(controlName?: string): boolean {
		if (controlName) {
			return this.orderForm.get(controlName)?.hasError('required') && this.orderForm.get(controlName)?.touched;
		}
		return this.orderForm?.invalid;
	}

	isValidByPattern(controlName?: string): boolean {
		if (controlName) {
			return this.orderForm.get(controlName)?.errors && !this.orderForm.get(controlName)?.hasError('required');
		}
		return this.orderForm.invalid;
	}

	getErrorsForm(controlName) {
		if (controlName) {
			return this.orderForm.get(controlName).errors;
		}

		return null;
	}

	private _customPhoneValidator(control: AbstractControl) {
		const phoneUtil: PhoneNumberUtil = PhoneNumberUtil.getInstance();
		try {
			const phoneNumber = phoneUtil.parse(control?.value);
			if (phoneUtil.isValidNumber(phoneNumber) && phoneUtil.isPossibleNumber(phoneNumber)) {
				return null;
			} else {
				return {invalidPhone: true};
			}
		} catch (e) {
			return {invalidCountryCode: true};
		}
	}

	onOrder() {
		super.onOrder();
		const postalCodeInOrder =
			this.order && this.order.preorder && this.order.preorder.postalCode && this.order.preorder.postalCode.length > 0;
		if (this.order && this.order.preorder) {
			if (this.repository._previousPreorder) {
				if (!this.order.preorder.name) {
					this.order.preorder.name = this.repository._previousPreorder.name;
				}
				if (!this.order.preorder.email) {
					this.order.preorder.email = this.repository._previousPreorder.email;
				}
				if (!this.order.preorder.phone) {
					this.order.preorder.phone = this.repository._previousPreorder.phone;
				}
				if (!this.order.preorder.companyName) {
					this.order.preorder.companyName = this.repository._previousPreorder.companyName;
				}
				if (!this.order.preorder.floor) {
					this.order.preorder.floor = this.repository._previousPreorder.floor;
				}
				if (!this.order.preorder.subcard) {
					this.order.preorder.subcard = this.repository._previousPreorder.subcard;
				}
			}
		}
		this.hadPostalCode = this.hadPostalCode || postalCodeInOrder;
		this.cdr.detectChanges();
	}

	onAddress() {
		super.onAddress();
		this.hadPostalCode =
			this.hadPostalCode || (this.address && this.address.postalCode && this.address.postalCode.length > 0);
		this.reloadAddress();
	}

	onCustomer() {
		super.onCustomer();
		if (this.customer) {
			if (this.customer.postalCode && this.customer.postalCode.length > 0) {
				this.hadPostalCode = true;
			}
			if (!this.order.preorder.email) {
				this.order.preorder.email = this.customer.email;
			}
			if (!this.order.preorder.name) {
				this.order.preorder.name = this.customer.name;
			}
			if (!this.order.preorder.companyName) {
				this.order.preorder.companyName = this.customer.companyName;
			}
			if (!this.order.preorder.phone) {
				this.order.preorder.phone = this.customer.phone;
			}
			if (!this.order.preorder.floor) {
				this.order.preorder.floor = this.customer.floor;
			}
			if (!this.order.preorder.subcard) {
				this.order.preorder.subcard = this.customer.subcard;
			}
			if (this.order.preorder.type === PreorderType.DELIVERY) {
				if (!this.order.preorder.street) {
					this.order.preorder.street = this.customer.street;
				}
				if (!this.order.preorder.postalCode) {
					this.order.preorder.postalCode = this.customer.postalCode;
				}
				if (!this.order.preorder.city) {
					this.order.preorder.city = this.customer.city;
				}
				if (!this.order.preorder.number && this.order.preorder.street === this.customer.street) {
					this.order.preorder.number = this.customer.number;
				}
			}
		}
	}

	async close() {
		await sleep(100);
		const firstErrorElement: HTMLElement = [...this.content.el.children].find(el => {
			return el.className.split(' ').findIndex(clazz => clazz === 'input-error') >= 0;
		});
		if (firstErrorElement !== undefined) {
			await this.content.scrollToPoint(0, firstErrorElement.offsetTop, 300);
		}

		const data = {
			preorder: this.order.preorder,
			orderAt: this.order.orderAt,
			asap: this.order.asap,
			tip: this.tip
		};
		console.log('data', data);
		this.repository.previousPreorder.emit(this.order.preorder);
		await this.modalCtrl.dismiss(data);
	}

	async dismiss() {
		await this.modalCtrl.dismiss();
	}

	reloadAddress() {
		if (
			this.order &&
			this.order.preorder &&
			this.address &&
			this.order.preorder.type === PreorderType.DELIVERY &&
			this.orderForm
		) {
			this.orderForm.patchValue({
				city: this.address?.city ?? '',
				street: this.address?.street ?? '',
				postcode: this.address?.postalCode && this.address?.postalCode?.length > 0 ? this.address.postalCode : '',
				houseNumber: this.address?.number
			});
		}
	}
	async openTos() {
		window.open(TosPage.url, '_blank');
	}

	async openPrivacy() {
		window.open(PrivacyPage.url, '_blank');
	}

	slotToText(slot: Moment): string {
		const asapPossible = (OrderUtils.isInside(this.order) || OrderUtils.isTakeAway(this.order)) && this.isVenueOpen;
		if (!slot) {
			return this.translate.instant(asapPossible ? 'checkout_modal.first_slot' : 'checkout_modal.choose_slot');
		}
		const slotMoment = moment(slot);
		const sameDay = TimeUtils.isSameDate(slotMoment, moment());
		if (sameDay) {
			return slotMoment.format('HH:mm');
		} else {
			return slotMoment.format('DD.MM. HH:mm');
		}
	}

	onVenue() {
		this.isVenueOpen = isVenueOpen(this.venue);
	}

	onVenueError(error) {
		super.onVenueError(error);
		this.isVenueOpen = false;
		if (this.order) {
			this.order.asap = false;
		}
	}

	hiddenInCart(article: Article): boolean {
		return article.tags && article.tags.find(tag => tag.identifier === 'hide_cart') !== undefined;
	}

	async onButtonClick() {
		if (this.order?.preorder?.type === PreorderType.DELIVERY) {
			if (!(await this.submitFormCustomValidator())) {
				return;
			}
		}
		this.updateForm();
		if (!this.isOverviewPage) {
			await this.toOverview();
		} else {
			await this.close();
		}
	}
	async submitFormCustomValidator() {
		let controlStreetValue = this.getFormControlValue('street').trim();
		let controlHouseNumberValue = this.getFormControlValue('houseNumber').trim();
		let controlPostalCodeValue = this.getFormControlValue('postcode').trim();
		let controlCityValue = this.getFormControlValue('city').trim();
		if (
			OrderUtils.isDelivery(this.order) &&
			(controlStreetValue !== this.address.street ||
				controlPostalCodeValue !== this.address.postalCode ||
				controlCityValue !== this.address.city ||
				controlHouseNumberValue !== this.address.number)
		) {
			try {
				const pred =
					controlStreetValue + ' ' + controlHouseNumberValue + ', ' + controlCityValue + ' ' + controlPostalCodeValue;
				const geocode = await MapsUtils.getPlace(pred);
				const address = MapsUtils.placeToAddress(geocode);
				const addressValidationResult = ValidationUtils.validateAddress(address, true);
				if (addressValidationResult) {
					// this.streetError = this.translate.instant(addressValidationResult);
					this.snackbarCtrl.open(this.translate.instant('errors.street_not_found'), null, {
						duration: 2000
					});
					this.orderForm.controls['street'].setErrors({street_not_found: true});
					console.log('errorDelivers');

					return false;
				}
				const delivers =
					(this.venue.deliveryByRadius &&
						calculateGeoDistance(
							address.lat,
							address.lng,
							this.venue.location.coordinates[1],
							this.venue.location.coordinates[0]
						) <= this.venue.deliveryRadius) ||
					(this.venue.isPostalDelivery && this.venue.deliveryPostalCodes.indexOf(address.postalCode));
				if (!delivers) {
					console.log('errorDelivers');

					this.orderForm.controls['street'].setErrors({street_delivery: true});

					this.snackbarCtrl.open(this.translate.instant('errors.street_delivery'), null, {
						duration: 2000
					});
					return false;
				}
				this.repository.address.emit(address);
				this.orderForm.patchValue({
					street: this.address.street
				});
				return true;
			} catch (e) {
				console.log('errorDelivers');

				this.orderForm.controls['street'].setErrors({street_not_found: true});
				this.snackbarCtrl.open(this.translate.instant('errors.street_not_found'), null, {
					duration: 2000
				});
				return false;
			}
		} else {
			return true;
		}
	}
	async toOverview() {
		await this.slides.update();
		await this.slides.slideNext();
		console.log('Slide next');
		this.isOverviewPage = true;
	}
	async editPersonalData() {
		await this.slides.slideTo(0);
		this.isOverviewPage = false;
	}
	slotSelected($event) {
		this.order.orderAt = $event;
		if (!$event) {
			this.order.asap = true;
		} else {
			this.order.asap = false;
		}
	}
	async backOrDismiss() {
		if (this.isOverviewPage) {
			await this.editPersonalData();
		} else {
			await this.dismiss();
		}
	}
	async loadSlots() {
		this.slots = [];
		this.order.orderAt = null;
		if (!this.venue) {
			this.snackbarCtrl.open(this.translate.instant('errors.venue'), null, {
				duration: 2000
			});
			return;
		}
		if (this.loading) {
			return;
		}
		this.loading = true;
		try {
			this.slots = (await getSlotsByOrder(this.venue, this.order)).map(slot => moment(slot.time));
			if (this.slots.length === 0) {
				this.loading = false;
				this.snackbarCtrl.open(this.translate.instant('checkout_modal.no_slots'), null, {
					duration: 2000
				});
				return;
			}
			if (this.isDeliveryAndCloseRestaurant() && OrderUtils.isDelivery(this.order)) {
				this.showTimeSlot(this.slots);
			}
			if (
				(OrderUtils.isInside(this.order) || OrderUtils.isTakeAway(this.order)) &&
				TimeUtils.doesHoursMatchNow(this.venue.openingHours)
			) {
				if (TimeUtils.isShowAsap(this.venue, this.order.preorder.type, this.order)) {
					this.slots = [null, ...this.slots];
				}
			}
		} catch (e) {
			this.snackbarCtrl.open(this.translate.instant('checkout_modal.time_error'), null, {
				duration: 2000
			});
			console.error(e);
		}
		this.loading = false;
	}
	priceOfOption(articleGroup: ArticleGroup, articleOption: ArticleOption): number {
		const price = getPrice(articleOption.article, OrderType.PREORDER, this.order.preorder.type);
		if (OrderUtils.isBogoOrFreeArticlePromo(this.order) && articleGroup.isPromo) {
			return 0;
		}
		return price * articleOption.quantity;
	}

	async updateForm() {
		this.order.preorder.name = this.getFormControlValue('fullName');
		this.order.preorder.phone = this.getFormControlValue('phone');
		this.order.preorder.email = this.getFormControlValue('email');
		this.order.preorder.note = this.getFormControlValue('note');
		this.order.orderAt = this.getFormControlValue('orderAt');
		if (!this.order.orderAt) {
			this.order.asap = true;
		} else {
			this.order.asap = false;
		}
		if (this.order.preorder.type === PreorderType.DELIVERY) {
			this.order.preorder.city = this.getFormControlValue('city');
			this.order.preorder.postalCode = this.getFormControlValue('postcode');
			this.order.preorder.number = this.getFormControlValue('houseNumber');
			this.order.preorder.street = this.getFormControlValue('street');
			// this.order.asap = true;
		}
		this.repository.order.emit(this.order);
	}

	checkPrevOrder() {
		if (this.order && this.order.preorder) {
			if (this.repository._previousPreorder) {
				this.orderForm.patchValue({
					fullName: this.repository._previousPreorder?.name ?? '',
					email: this.repository._previousPreorder?.email ?? '',
					phone: this.repository._previousPreorder?.phone ?? '',
					note: this.repository._previousPreorder?.note ?? ''
				});
			}
		}
	}
	showTimeSlot(slots: any[]) {
		this.firstSlotDelivery = slots[0].diff(moment(), 'minutes');
		this.orderForm.patchValue({
			orderAt: slots[0]
		});
	}
	isDeliveryAndCloseRestaurant() {
		const mathcNow = TimeUtils.doesHoursMatchNow(this.venue.deliveryHours);
		return mathcNow;
	}
}
