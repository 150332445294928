import {Component, EventEmitter, Input, Output} from '@angular/core';
import OptionGroup from '../../../smoothr-web-app-core/models/OptionGroup';
import ArticleOption from '../../../smoothr-web-app-core/models/ArticleOption';
import {DisplayIdentifier} from '../../../smoothr-web-app-core/enums/DisplayIdentifier';
import Article from '../../../smoothr-web-app-core/models/Article';
import {PreorderType} from '../../../smoothr-web-app-core/enums/PreorderType';
import {OrderType} from '../../../smoothr-web-app-core/enums/OrderType';
import {getAvailability, getPrice, numberD, numberToCurrency} from '../../../smoothr-web-app-core/utils/utils';
import {ValidationUtils} from 'src/smoothr-web-app-core/utils/validation-utils';
import {filterMatchingOptions, OrderUtils} from 'src/smoothr-web-app-core/utils/order-utils';
import FulfilledDependency from 'src/smoothr-web-app-core/models/FulfilledDependency';
@Component({
	selector: 'app-article-option-group-single',
	templateUrl: './article-option-group-single.component.html',
	styleUrls: ['article-option-group-single.component.scss']
})
export class ArticleOptionGroupSingleComponent {
	@Input() optionGroup: OptionGroup;
	@Input() selected: ArticleOption[];
	@Input() article: Article;
	@Output() selection = new EventEmitter<ArticleOption>();
	@Output() openInfo = new EventEmitter<Article>();
	@Input() preorderType: PreorderType;
	@Input() currency: string;
	@Input() groups: any[];
	selectedItem: Article;
	numberToCurrency = numberToCurrency;
	di = DisplayIdentifier;

	constructor() {}

	isHidden(article: Article): boolean {
		return this.preorderType && !getAvailability(article, OrderType.PREORDER, this.preorderType);
	}

	selectedOptionIndex() {
		const indexSelection = this.selected.findIndex(selectedOption => selectedOption.group === this.optionGroup._id);
		if (indexSelection < 0) {
			return indexSelection;
		}
		return this.optionGroup.articles.findIndex(article => article._id === this.selected[indexSelection].article._id);
	}

	onSelectionChange(article: Article) {
		this.selectedItem = article;
		const articleOption = new ArticleOption();
		articleOption.article = article;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = 1;
		this.selection.emit(articleOption);
	}

	isDisabled(option: Article): boolean {
		return option.requirements && option.requirements.min > 0 && this.isOptionSelected(option);
	}

	price(option: Article): number {
		let price = getPrice(option, OrderType.PREORDER, this.preorderType);
		if (this.fullPrice()) {
			price += getPrice(this.article, OrderType.PREORDER, this.preorderType);
		}
		// if (this.article.groupDependencies.map(it => it.dependencies.map(it => it.groupArticles)).includes(this.optionGroup._id))
		// check if optiongroup has dependencies to fulfill
		const allDependingAritcles = [];
		this.article.groupDependencies.forEach(it => {
			it.dependencies.forEach(dep => {
				allDependingAritcles.push(dep.articles);
				allDependingAritcles.push();
			});
		});
		const groupDependenciesIdsArray = [].concat.apply(
			[],
			this.article.groupDependencies.reduce((acc, val) => acc.concat(val.dependencies.map(it => it.groupArticles)), [])
		);
		const optionIds = this.optionGroup.articles.map(it => it._id);
		let hasDependencyInGroup = false;
		for (const optionId of optionIds) {
			hasDependencyInGroup = groupDependenciesIdsArray.includes(optionId);
			if (hasDependencyInGroup) {
				break;
			}
		}
		if (!hasDependencyInGroup) {
			if ((this.optionGroup as any).displayIdentifiers?.includes('sides') === true) {
				const minOfGroup = Math.min(
					...this.optionGroup.articles.map(it => getPrice(it, OrderType.PREORDER, this.preorderType))
				);
				price -= minOfGroup;
			}
			return price;
		}
		if (this.calculationPrice(option) > 0) {
			price = this.calculationPrice(option);
		}

		return price;
	}

	fullPrice(): boolean {
		return (
			this.optionGroup.displayIdentifiers &&
			this.optionGroup.displayIdentifiers.findIndex(di => di === DisplayIdentifier.fullPrice) >= 0
		);
	}

	isOptionSelected(option: Article): boolean {
		return (
			this.selected.find(sel => sel.article._id === option._id && sel.group === this.optionGroup._id) !== undefined
		);
	}

	openInfoClick(option: Article) {
		this.openInfo.emit(option);
	}
	dependenciesRegardingOptionArticle(opt: Article) {
		return this.article.groupDependencies.map(it => {
			if (it.dependencies.find(dependency => dependency.groupArticles.includes(opt._id)) == null) {
				return null;
			} else {
				this.groups.find(group => it.group == group._id);
			}
		});
	}
	calculationPrice(option: Article) {
		const groupsCopy = JSON.parse(JSON.stringify(this.groups));
		const articleOption = new ArticleOption();
		articleOption.article = option;
		articleOption.group = this.optionGroup._id;
		articleOption.quantity = 1;
		const optionDependency = ValidationUtils.isGroupDependencyFulfilled(this.article, groupsCopy, this.optionGroup);
		OrderUtils.addOption(groupsCopy, articleOption, this.optionGroup, optionDependency);
		const arrayOfPrice = this.article.groups
			.filter(it => {
				return (
					(it.displayIdentifiers as any).includes('sides') &&
					ValidationUtils.isGroupDependencyFulfilled(this.article, groupsCopy, it).times >= 0
				);
			})
			.map(it => {
				return it.articles
					.filter(art => {
						let transformPrice = numberD(art?.price);
						return transformPrice !== 0;
					})
					.map(art => {
						return numberD(art.price);
					});
			});
		const price = numberD(this.article.price) + Math.min(...[].concat.apply([], arrayOfPrice));
		return price;
	}
}
