import {Component, Input, OnInit} from '@angular/core';
import {environment} from 'src/environments/environment';
import Tag from '../../../smoothr-web-app-core/models/Tag';

@Component({
	selector: 'app-tag',
	templateUrl: './tag.component.html',
	styleUrls: ['tag.component.scss']
})
export class TagComponent implements OnInit {
	@Input() tags: Tag[];
	environment = environment;

	constructor() {}

	ngOnInit() {		
	}
}
